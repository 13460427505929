"use strict";
import "../../sass/home/home.scss";
import Swiper, { Navigation } from "swiper";

export default (function () {

    $(function () {
        initPage();
        initHomeSwiper();
    });

    function initPage() {
        const mainHero = document.querySelector(".main-hero");
        const mainHeader = document.querySelector(".main-header");

        if (!mainHero) {
            mainHeader.classList.add("main-header--no-hero");
        }
    }

    function initHomeSwiper() {
        new Swiper(".mySwiper", {
            modules: [Navigation],
            autoHeight: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }


}());